import styles from './styles.module.css';
import { BLOCKS } from '@contentful/rich-text-types';
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { CustomButton, CustomButtonProps } from 'src/components/formComponents/customButton';
import { CustomCheckbox } from 'src/components/formComponents/customCheckbox';
import { CustomInput } from 'src/components/formComponents/customInput';
import { CustomRadioGroup } from 'src/components/formComponents/customRadioGroup';
import { CustomSelectlist, notSelected } from 'src/components/formComponents/customSelectlist';
import { MainTabItem, MainTabPanel } from 'src/components/mainTabPanel';
import { CaretIcon } from 'src/icons/caret';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { ApolloManager } from 'src/utils/apollo-manager';
import formSubmission from 'src/utils/dynamicForm.graphql';
import { curatedTitle } from 'src/utils/curated-title';
import { SectionContainer, SectionContainerTitle } from 'src/components/sectionContainer';
import { ContentfulContactUsPage, ContentfulFormTab, ContentfulFormItem } from 'src/graphql-types';
import { LinkHelper } from 'src/utils/link-helper';

const MAX_TEXT_SIZE = 5_000; // virtually no limit, just a sanity check.

const queryQl = graphql`
	query ContactUsPage {
		allContentfulContactUsPage(sort: { fields: updatedAt, order: DESC }, limit: 1) {
			nodes {
				contentful_id
				title
				subtitle
				tabs {
					contentful_id
					title
					formInputs {
						contentful_id
						description
						shortInputText
						shortInputIsRequired
						longInputText
						longInputIsRequired
						dropdownLabel
						dropdownList
						dropdownListIsRequired
						formGroupTitle
						formGroupText {
							raw
						}
						checkbox
						checkboxIsRequired
						options
					}
				}
				contactDetailsItems {
					name
					email
					phone
					fax
					responsibilities
				}
			}
		}
	}
`;

interface FormItem {
	itemType?: FormItemType;
	inputName?: string;
	defaultValue?: string | boolean | null;
	item: ContentfulFormItem;
}

const richTextOptions = {
	...defaultRichTextOptions,
	renderNode: {
		...defaultRichTextOptions.renderNode,
		[BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => (
			<p className={styles.divisionParagraph}>{children}</p>
		),
	},
};

const requiredRule = {
	required: {
		value: true,
		message: 'This field is required',
	},
};
const textSizeRule = {
	validate: (value: string) => {
		if (value.length >= MAX_TEXT_SIZE) {
			return `input is too long`;
		}
	},
};
const selectListRule = {
	validate: (value: string) => {
		return value === notSelected ? 'Please select an option' : undefined;
	},
};

const getFormItemType = (item?: ContentfulFormItem | null): FormItemType | undefined => {
	if (!item) return undefined;
	if (item.shortInputText) return FormItemType.shortInput;
	if (item.longInputText) return FormItemType.longInput;
	if (item.dropdownList) return FormItemType.dropdownList;
	if (item.formGroupTitle) return FormItemType.formGroupTitle;
	if (item.formGroupText) return FormItemType.formGroupText;
	if (item.checkbox) return FormItemType.checkbox;
	if (item.options) return FormItemType.options;
	return undefined;
};

const getFormItemName = (
	item?: ContentfulFormItem | null,
	itemType?: FormItemType
): string | undefined => {
	if (!item) return undefined;

	switch (itemType) {
		case FormItemType.shortInput:
			return `${item.shortInputText}_|_${item.contentful_id}`;
		case FormItemType.longInput:
			return `${item.longInputText}_|_${item.contentful_id}`;
		case FormItemType.dropdownList:
			return `${item.dropdownLabel || 'Select'}_|_${item.contentful_id}`;
		case FormItemType.options:
			return `Option_|_${item.contentful_id}`;
		case FormItemType.checkbox:
			return `${item.checkbox}_|_${item.contentful_id}`;
		default:
			return undefined;
	}
};

const getFormItemDefaultValue = (item?: ContentfulFormItem | null, itemType?: FormItemType) => {
	if (!item) return undefined;

	switch (itemType) {
		case FormItemType.shortInput:
		case FormItemType.longInput:
			return '';
		case FormItemType.dropdownList:
			return notSelected;
		case FormItemType.options:
			const [firstOption] = item.options?.filter((o) => o) || [];
			return firstOption;
		case FormItemType.checkbox:
			return false;
		default:
			return undefined;
	}
};

enum FormItemType {
	shortInput,
	longInput,
	dropdownList,
	formGroupTitle,
	formGroupText,
	checkbox,
	options,
}

enum SubmitState {
	NotSubmitted,
	Submitting,
	SuccessfullySubmitted,
	UnsuccessfullySubmitted,
}

interface TabContentProps {
	tabTitle?: string | null;
	data?: ContentfulFormTab | null;
}

interface ContactUsResult {
	result: string;
}

const TabContent = (props: TabContentProps) => {
	if (props.data?.formInputs?.length) {
		const { contentful_id, title, formInputs } = props.data;
		const tabTitle = props.tabTitle;
		const { register, handleSubmit, errors, reset, setValue } = useForm();
		const [submitState, setSubmitState] = useState(SubmitState.NotSubmitted);
		const [submitMessage, setSubmitMessage] = useState<string | undefined>(undefined);

		useEffect(() => {
			setSubmitState(SubmitState.NotSubmitted);
			setSubmitMessage(undefined);
			reset();
		}, [tabTitle]);

		const inputs =
			formInputs
				?.filter((item) => item)
				.map((item) => {
					const itemType = getFormItemType(item);
					return {
						itemType,
						inputName: getFormItemName(item, itemType),
						defaultValue: getFormItemDefaultValue(item, itemType),
						item: item!,
					};
				}) || [];

		const onSubmit = async (data: any) => {
			setSubmitState(SubmitState.Submitting);

			let submissionDetails = [];
			for (let submissionDetail in data) {
				if (submissionDetail !== 'areYouAHuman') {
					const sdValue = data[submissionDetail];
					let item = { fieldName: submissionDetail } as any;
					if (typeof sdValue === 'boolean') {
						item.booleanValue = sdValue;
					} else if (typeof sdValue === 'string') {
						item.stringValue = sdValue;
					} else {
						item.stringValue = JSON.stringify(sdValue);
					}
					submissionDetails.push(item);
				}
			}

			try {
				await ApolloManager.client.mutate<ContactUsResult>({
					mutation: formSubmission,
					variables: {
						formName: tabTitle,
						isItARobot: data.areYouAHuman,
						values: submissionDetails,
					},
				});
				setSubmitState(SubmitState.SuccessfullySubmitted);
				setSubmitMessage('Thank you, your details have been submitted.');
				clearInputs();
			} catch (error) {
				console.error('Error Submitting Contact Us Form: ', error, data);
				setSubmitState(SubmitState.UnsuccessfullySubmitted);
				setSubmitMessage(
					'There was an error processing your submission. Please try again later or contact us by phone. We apologise for the inconvenience'
				);
			}
		};

		const clearInputs = () => {
			inputs.forEach((input) => {
				// input.defaultValue could be '' or false
				if (input.inputName && input.defaultValue !== undefined && input.defaultValue !== null) {
					setValue(input.inputName, input.defaultValue, false);
				}
			});
		};

		const onReset = () => {
			setSubmitState(SubmitState.NotSubmitted);
			setSubmitMessage(undefined);
			reset();
		};

		const getFormItemJsx = (formItem: FormItem) => {
			if (!formItem) {
				return undefined;
			}

			const itemType = formItem.itemType;
			const name = formItem.inputName || formItem.item.contentful_id!;

			switch (itemType) {
				case FormItemType.shortInput:
					return (
						<CustomInput
							name={name}
							placeHolder={formItem.item.shortInputText}
							type="text"
							outerContainerStyle={{ margin: '0' }}
							ref={
								formItem.item.shortInputIsRequired
									? register({ ...requiredRule, ...textSizeRule })
									: register(textSizeRule)
							}
							errorMessage={errors ? errors[name]?.message : undefined}
						/>
					);
				case FormItemType.longInput:
					return (
						<div className={styles.textAreaContainer}>
							<CustomInput
								name={name}
								placeHolder={formItem.item.longInputText}
								type="textArea"
								outerContainerStyle={{ margin: '0', position: 'absolute' }}
								ref={
									formItem.item.longInputIsRequired
										? register({ ...requiredRule, ...textSizeRule })
										: register(textSizeRule)
								}
								errorMessage={errors ? (errors[name] as any)?.message : undefined}
							/>
						</div>
					);
				case FormItemType.formGroupTitle:
					return <h6 className={styles.divisionTitle}>{formItem.item.formGroupTitle}</h6>;
				case FormItemType.formGroupText:
					return getRichText(formItem.item.formGroupText, richTextOptions);
				case FormItemType.dropdownList:
					return (
						<CustomSelectlist
							name={name}
							placeHolder={formItem.item.dropdownLabel || 'Select'}
							outerContainerStyle={{ margin: '0' }}
							ref={formItem.item.dropdownListIsRequired ? register(selectListRule) : register}
							errorMessage={errors ? (errors[name] as any)?.message : undefined}
							values={
								formItem.item.dropdownList
									?.filter((v) => v !== null && v !== undefined)
									.map((v) => ({ value: v!, label: v! })) || []
							}
						/>
					);
				case FormItemType.options:
					return (
						<CustomRadioGroup
							name={name}
							ref={register}
							className={styles.optionsContainer}
							labelContainerStyle={{ marginRight: '20px' }}
							options={
								formItem.item.options
									?.filter((o) => o !== null && o !== undefined)
									.map((o, i) => ({
										value: o!,
										label: o!,
										selected: i === 0,
									})) || []
							}
						/>
					);
				case FormItemType.checkbox:
					return (
						<CustomCheckbox
							name={name}
							labelJsx={formItem.item.checkbox}
							labelClassName={styles.checkboxLabel}
							containerStyle={{ margin: '20px 0' }}
							ref={formItem.item.checkboxIsRequired ? register(requiredRule) : register}
							errorMessage={errors ? (errors[name] as any)?.message : undefined}
						/>
					);
				default:
					return undefined;
			}
		};

		// organises form items in rows.
		// short inputs and dropdownlist can go alongside
		// other form items need their own row (text-areas, checkboxes, etc.)
		let rows: FormItem[][] = [];
		let i = 0;
		while (i < (inputs?.length || -1)) {
			const iItem = inputs![i];
			let row = [iItem];
			const iType = iItem.itemType;
			if (iType === FormItemType.shortInput || iType === FormItemType.dropdownList) {
				const j = i + 1;
				if (j < inputs!.length) {
					const jItem = inputs![j];
					const jType = jItem.itemType;
					if (jType === FormItemType.shortInput || jType === FormItemType.dropdownList) {
						row.push(jItem);
						i = j;
					}
				}
			}
			rows.push(row);
			i++;
		}

		const getButtonJsx = () => {
			let buttonProps: CustomButtonProps = {
				type: 'submit',
				disabled: false,
				content: 'Submit',
				iconRight: <CaretIcon paddingPx={{ left: 80 }} />,
				variant: 'primary',
				inlineStyle: { margin: '0' },
			};
			switch (submitState) {
				case SubmitState.Submitting:
					buttonProps.content = 'Submitting';
					buttonProps.disabled = true;
					break;
				case SubmitState.SuccessfullySubmitted:
					buttonProps.content = 'Submitted';
					buttonProps.disabled = true;
					buttonProps.iconRight = undefined;
					break;
				case SubmitState.UnsuccessfullySubmitted:
					buttonProps.content = 'Reset';
					buttonProps.type = 'reset';
					buttonProps.variant = 'secondary';
					break;
			}

			return <CustomButton {...buttonProps} />;
		};

		return (
			<SectionContainer isGrey withBorderTop>
				<form className={styles.gridForm} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
					<div className={styles.areYouAHuman}>
						<CustomCheckbox
							name="areYouAHuman"
							labelJsx={'Please leave this un--ticked'}
							ref={register}
							tabIndex={-1}
							autoComplete="off"
						/>
					</div>
					{rows.map((row, i) => {
						if (row.length === 1) {
							return <div key={`${i}_${row[0]?.item.contentful_id}`}>{getFormItemJsx(row[0])}</div>;
						} else if (row.length === 2) {
							return (
								<div key={`${i}_${row[0]?.item.contentful_id}`} className={styles.grid2cols}>
									<div>{getFormItemJsx(row[0])}</div>
									<div>{getFormItemJsx(row[1])}</div>
								</div>
							);
						}
					})}
					<div className={styles.submit}>{getButtonJsx()}</div>
					{submitMessage && (
						<div className={styles.submitMessageContainer}>
							<p>{submitMessage}</p>
						</div>
					)}
				</form>
			</SectionContainer>
		);
	}
	return <div className={styles.noContent}>This tab has no content</div>;
};

interface QueryResult {
	allContentfulContactUsPage: {
		nodes: ContentfulContactUsPage[];
	};
}

export const ContactUsPage = () => {
	const [activeTab, setActiveTab] = useState<string | null>(null);
	const data = useStaticQuery<QueryResult>(queryQl);
	const [pageData] =
		(data.allContentfulContactUsPage?.nodes?.length || 0) > 0
			? data.allContentfulContactUsPage.nodes
			: [];

	if (!pageData) {
		console.error('No page data for this ContactUsPage');
		return (
			<SectionContainer withBorderTop>
				<p className={styles.message}>This page is under maintenance. Please try again later</p>
			</SectionContainer>
		);
	}

	const { contentful_id, title, subtitle, tabs, contactDetailsItems } = pageData;

	const tabTitles: string[] =
		tabs && tabs.length
			? tabs.map((tab, i) => {
					if (tab?.title) {
						return encodeURIComponent(LinkHelper.parseInternalLink(tab.title));
					} else {
						return '' + i;
					}
			  })
			: [];

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.location.hash) {
				let tab = window.location.hash.replace('#', '');
				if (activeTab !== tab) {
					setActiveTab(tab);
				}
			} else if (tabTitles.length > 0) {
				history.replaceState(null, document.title, `#${tabTitles[0]}`);
				setActiveTab(tabTitles[0]);
			}
		}
	});

	// fix for - links to this page don't scroll page to top on mobile viewport.
	// only needs to trigger on first render.
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.scrollTo({
				top: 0,
				behavior: 'auto',
			});
		}
	}, []);

	const getActiveTabContent = () => {
		const index = tabTitles.findIndex((tab) => tab === activeTab);
		if (tabs && index >= 0 && index < tabs.length) {
			return tabs[index]!;
		} else {
			return undefined;
		}
	};

	const activeTabContent = getActiveTabContent();

	const onTabClick = (tab: string) => {
		history.pushState(null, document.title, `#${tab}`);
		setActiveTab(tab);
	};

	return (
		<div>
			<Helmet>
				<title>{curatedTitle(title)}</title>
			</Helmet>
			<SectionContainer style={{ paddingBottom: '0' }} withBorderTop>
				<SectionContainerTitle text={title} titleStyle={{ color: '#323E6B' }} />
				{subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
				<MainTabPanel style={{ justifyContent: 'flex-start', paddingLeft: '0', paddingRight: '0' }}>
					{(tabs?.length || -1) === tabTitles.length &&
						tabs
							?.filter((tab) => tab)
							.map((tab, i) => {
								return (
									<MainTabItem
										key={`${i}_${tab?.contentful_id}`}
										text={tab?.title}
										style={{ marginRight: '40px' }}
										isActive={activeTab === tabTitles[i]}
										onClick={() => onTabClick(tabTitles[i])}
									/>
								);
							})}
				</MainTabPanel>
			</SectionContainer>
			{activeTabContent && (
				<TabContent tabTitle={activeTabContent.title || ''} data={activeTabContent} />
			)}
			{contactDetailsItems && (
				<SectionContainer isGrey withBorderTop>
					<div className={styles.detailsCardContainer}>
						{contactDetailsItems
							.filter((contact) => contact)
							.map((contact, i) => {
								return (
									<div key={`${i}_${contact?.contentful_id}`} className={styles.detailsCard}>
										<h6 className={styles.detailsCardTitle}>{contact?.name}</h6>
										<ul>
											{contact?.email && (
												<li>
													Email:{' '}
													<a className={styles.contactLink} href={`mailto:${contact.email}`}>
														{contact.email}
													</a>
												</li>
											)}
											{contact?.phone && (
												<li>
													Phone:{' '}
													<a className={styles.contactLink} href={`tel:${contact.phone}`}>
														{contact.phone}
													</a>
												</li>
											)}
											{contact?.fax && <li>Fax: {contact.fax}</li>}
											{contact?.responsibilities &&
												contact.responsibilities.filter((r) => r).map((r) => <li key={r!}>{r}</li>)}
										</ul>
									</div>
								);
							})}
					</div>
				</SectionContainer>
			)}
		</div>
	);
};

export default () => (
	<DefaultLayoutTemplate>
		<ContactUsPage />
	</DefaultLayoutTemplate>
);
